/* custom-datepicker.css */

.custom-datepicker-header {
  background-color: rgb(38, 154, 137);
  padding: 10px;
  border-radius: 4px;
}
.MuiStack-root {
  overflow: visible !important;
  margin: 10px 0;
}

.MuiSelect-root {
  background-color: white;
  color: black;
  border-radius: 4px;
  margin: 0 5px;
}

.MuiSelect-root .MuiSvgIcon-root {
  color: black;
}

.MuiPickersDay-dayWithMargin {
  margin: 2px;
}

.MuiPickersDay-root {
  background-color: white;
  color: black;
}
.MuiPickersDay-root:not(.Mui-selected):hover {
  background-color: rgb(38 154 137 / 47%) !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: rgb(38, 154, 137); /* Background color for selected day */
  color: white;
}

.MuiPickersCalendarHeader-dayLabel {
  background-color: rgb(38, 154, 137); /* Background color for day names */
  color: white;
}

.MuiDayPicker-weekContainer {
  display: flex;
  justify-content: space-between;
}

.MuiPickersCalendarHeader-root {
  background-color: rgb(38, 154, 137); /* Background color for header */
  color: white;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.MuiPickersCalendarHeader-label {
  color: white;
}

.MuiPickersCalendarHeader-switchViewButton {
  color: white;
}

.MuiIconButton-root {
  color: rgb(38, 154, 137) !important;
  padding: 7px !important;
  margin-right: 10px !important;
  width: 30px !important; /* Set the width of the button */
  height: 30px !important; /* Set the height of the button */
}

/* .MuiInputBase-root {
    color: #000000; 
    border-radius:8px !important;
    outline:1px solid #B9DDD8;
    height:40px;
    padding:6px 10px 5px 11px;
    font-size:14px;
  }
  .MuiInputBase-root.Mui-focused{
      box-shadow:0 0 0 .1rem rgba(39, 153, 137, 1) !important;
  }
  .MuiInputBase-root .MuiInputBase-input{
       height:39px;
       padding:6px 0px 5px 0px;
       box-sizing:border-box;
       height: 39px;
        padding: 6px 0px 5px 0px;
        box-sizing: border-box;
        overflow: hidden;
        font-size: 14px;
        font-family: 'Lora', sans-serif;
        color: #000000;
  } */
.MuiStack-root {
  overflow: visible !important;
  padding-top: 0px !important;
}
.MuiDatePickerToolbar-root {
  display: none !important;
}
/* .MuiDialogActions-root .MuiButtonBase-root{     /// this styles are affected to cancel and yes buttons popup
      padding:4px 8px !important;
      font-size:12px !important;
      border:1px solid #B9DDD8 !important;
      color: #1E3037 !important;
  }
  .MuiDialogActions-root .MuiButtonBase-root:last-child{
    background:rgb(38, 154, 137) !important;
    border-color:rgb(38, 154, 137) !important;
    color:#ffffff !important;
  } */

.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

/* custom-datepicker.css */
.MuiPickersDay-daySelected {
  background-color: rgb(38, 154, 137); /* Dark green for selected date */
  color: white; /* Text color for selected date */
}

/* .MuiButtonBase-root.MuiPickersDay-day[data-current="true"] {
    background-color: lightgreen;
} */

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: rgb(38, 154, 137) !important;
  color: white !important;
}
.MuiPaper-root {
  box-shadow: 0px 4px 12.3px 0px #8bbdd266 !important;
  border-radius: 6px !important;
}

.MuiPickersPopper-root[data-popper-placement="bottom-start"]
  .MuiPickersLayout-root {
  margin-top: 14px;
  position: relative;
}
.MuiPickersPopper-root[data-popper-placement="top-start"]
  .MuiPickersLayout-root {
  margin-bottom: 14px;
  position: relative;
}
.MuiPickersLayout-root:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 7px solid black;
  border-color: #269a89 #269a89 transparent transparent;
  transform-origin: 0 0;
  transform: rotate(-45deg) translateX(-50%);
  top: -4px;
  left: 50%;
}
.MuiPickersPopper-root[data-popper-placement="top-start"]
  .MuiPickersLayout-root::before {
  bottom: -7px;
  top: initial;
  border-color: transparent transparent #ffffff #ffffff;
  box-shadow: -2px 2px 2px 0px #e0edf3;
}

.MuiPickersYear-yearButton.Mui-selected,
.MuiPickersMonth-monthButton.Mui-selected {
  background-color: rgb(38, 154, 137) !important;
}
.MuiPickersYear-yearButton:not(.Mui-selected):hover,
.MuiPickersMonth-monthButton:not(.Mui-selected):hover {
  background-color: rgb(38 154 137 / 47%) !important;
}

.MuiPickersCalendarHeader-switchViewIcon,
.MuiPickersArrowSwitcher-button svg {
  fill: #ffffff !important;
}

.MuiPickersArrowSwitcher-button {
  fill: #ffffff !important;
  margin-right: -5px !important; /* Move the buttons 20px to the left */
}

.MuiPickersCalendarHeader-label {
  font-family: "Arvo" !important;
  font-size: 0.96rem !important;
  min-width: 130px; /* Adjust this value as needed */
  margin-left: -20px;
}
.MuiDayCalendar-weekDayLabel,
.MuiPickersDay-root {
  font-family: "Arvo" !important;
  color: #48464d !important;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  height: 1.7rem !important;
  font-size: 0.8rem !important;
  margin: 0.178rem !important;
}
/* .MuiPickersDay-root{
    font-family: var(--font-arvo) !important;
    margin: 0.178rem !important;
    width: 1.7rem !important;
    line-height: 1.7rem !important;
    height:1.7rem !important;
    font-size: 0.8rem !important;
    color: #000 !important;
} */

.MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
  color: #ccc !important;
}
.MuiMonthCalendar-root {
  height: 100% !important;
  width: 256px !important;
  max-height: 290px !important;
  padding-bottom: 10px !important;
}
.MuiYearCalendar-root {
  height: 100% !important;
  width: 256px !important;
  max-height: 250px !important;
}
.MuiPickersArrowSwitcher-spacer {
  width: 13px !important;
}
.MuiPickersCalendarHeader-switchViewButton {
  padding: 0px !important;
}

.MuiDateCalendar-root {
  width: 256px !important;
  height: 290px !important;
}
/* .MuiYearCalendar-root .MuiPickersYear-yearButton{
    font-size:0.8rem !important;
    width: 61px !important;
    font-family: var(--font-arvo) !important;
} */
.MuiYearCalendar-root .MuiPickersYear-yearButton,
.MuiPickersMonth-monthButton {
  font-size: 0.8rem !important;
  width: 61px !important;
  font-family: "Arvo" !important;
  height: 32px;
}
.MuiPickersDay-today {
  border: 0px !important;
  /* background-color: rgb(38 154 137 / 47%) !important;
    font-weight: bold !important; */
}
.MuiPickersYear-yearButton:not(.Mui-selected):focus,
.MuiPickersMonth-monthButton:not(.Mui-selected):focus,
.MuiPickersDay-root:not(.Mui-selected):focus {
  background-color: rgb(38 154 137 / 47%) !important;
  color: #279989 !important;
}
