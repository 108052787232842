body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-para{
 margin-left: -11rem;
 margin-bottom: -24px;
}
/* styles.css */
/* addmedication form need to change after completion of page */
.custom-textarea {
  min-height: 80px;
  padding-left: 0px;
}

.custom-textarea .MuiInputBase-root {
  min-height: 80px;
  padding-left: 0px;
}
.custom-textarea1{
  min-height: 60px;
  padding-left: 0px;
}
.custom-textarea1 .MuiInputBase-root {
  min-height: 60px;
  padding-left: 0px;
}
/* .css-yrvk59-MuiButtonBase-root-MuiMenuItem-root:before{
  background-color: transparent !important;
} */
/* .css-yrvk59-MuiButtonBase-root-MuiMenuItem-root+.MuiDivider-root {
  margin-top: 0px !important;
} */
 
/* reports datagrid css */
/* Targeting filter form fields */
/* .MuiDataGrid-filterForm .MuiInputBase-root {
  width: 100%; 
  height: 40px; 
  margin-top: 22px;
  display: flex;
  align-items: center; 
} */

/* Styling the input and select components */
/* .MuiDataGrid-filterForm .MuiInput-root {
  padding: 8px 12px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
} */

/* Ensuring label alignment */
/* .MuiDataGrid-filterForm label {
  margin: 1;
  padding-bottom: 4px; 
} */

/* Specific styling for the select component */
/* .MuiDataGrid-filterForm .MuiSelect-root {
  min-width: 120px; 
  max-width: 200px; 

} */
/* .MuiDataGrid-filterForm{
  padding: 16px !important;
  gap:5px !important;
} */
/* .css-1cuczwr-MuiFormControl-root-MuiTextField-root .MuiInputBase-root {
  margin-top: 22px !important;
  height:40px !important;
  min-width:120px !important;
  
}
.css-1cuczwr-MuiFormControl-root-MuiTextField-root{
  margin: 0px !important;
} */
/* .css-18pq2zy-MuiFormControl-root-MuiDataGrid-filterFormDeleteIcon{
  align-self: flex-start !important;
} */
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus{
  outline: none !important;
}
/* //.css-qo3tsd */
/* .MuiDataGrid-root {
  padding: 0 !important;
  margin: 0 !important;
  border: 0px solid #ffff !important;  
} */
.hover-tooltip {
  display: none;
}
.MuiListItem-root:hover .hover-tooltip {
  display: block;
}
/* .css-ihypr4-MuiInputBase-root-MuiInput-root-MuiSelect-root::before{
  border-bottom: 0px !important;
}
.css-ihypr4-MuiInputBase-root-MuiInput-root-MuiSelect-root::after{
  border-bottom: 0px !important;
}
.css-4oj0i6-MuiInputBase-root-MuiInput-root::before{
  border-bottom: 0px !important;
}
.css-4oj0i6-MuiInputBase-root-MuiInput-root::after{
  border-bottom: 0px !important;
} */


