.App {
  text-align: center;
  font-family: "Lora";
  width: "100%";
  height: "100vh";
  justify-content: center;
}

::selection {
  background: #279989; /* Background color of the selected text */
  color: #ffffff; /* Text color of the selected text */
}
::placeholder {
  color: #7a8a86 !important;
  opacity: 0.7 !important; /* Firefox */
  font-family: "Lora";
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #7a8a86 !important;
  opacity: 0.7 !important;
}

.row {
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.star {
  color: #279989;
}
.optional {
  color: #7a8a86 !important;
  font-size: 11px !important;
  opacity: 0.7 !important; /* Firefox */
  font-family: "Lora";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important; /* Keep your custom border */
  -webkit-text-fill-color: #48464d !important; /* Text color */
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important; /* Background color */
  transition: background-color 5000s ease-in-out 0s !important; /* Avoid flashing background */
  height: 10px !important; /* Set your desired height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0s linear;
  }
}

.App-header {
  background-color: #235ed3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar for horizontal scrolling */
}

::-webkit-scrollbar-thumb {
  background: #bfcac7; /* Thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #27998966; /* Darker color when hovering */
}

/* Toastify.css */
.Toastify__progress-bar {
  background-color: #279989 !important; /* Change the progress bar color */
}

/* Change the color of the tick icon in success toasts */
.Toastify__toast--success .Toastify__toast-icon {
  color: #279989 !important; /* Add !important to ensure it overrides any existing styles */
}

/* If the icon is within another element, you can try targeting it more specifically */
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #279989 !important; /* For SVG icons */
}

.Toastify__toast {
  font-family: "Arvo" !important; /* Change to your desired font */
  /* color: #279989; Change the text color */
}

/* Specific styles for different types of toasts */
.Toastify__toast--success {
  color: #279989; /* Change success text color */
}

/* Change the progress bar color after it has been cleared */
.Toastify__progress-bar--cleared {
  background-color: white !important; /* Change this to your desired cleared color */
}

/* Optional: Change color on hover */
.Toastify__close-button:hover {
  color: #279989 !important; /* Change hover color */
}

.Toastify__progress-bar--error {
  background-color: #d84141 !important; /* Red for error progress bar */
}

.Toastify__toast--error .Toastify__close-button:hover {
  color: #d84141 !important; /* Change hover color for error toasts */
}

.Toastify__toast--error .Toastify__toast-icon {
  color: #d84141 !important; /* Change the error icon color */
}

.Toastify__toast--error .Toastify__toast-icon {
  color: #d84141 !important; /* Change error icon hover color */
}

.Toastify__toast--error {
  font-family: "Arvo" !important; /* Set custom font for error toast */
  /* color: #d84141 !important; Set text color to red for error toast */
}
