.Login {
  text-align: center;
  background-color: #279989;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-image:url('../../assets/images/login/login_background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.error-message{
  color:red;
  font-size: 12px;
  margin-bottom: -5px;
  padding:0;
  text-align: left;
  margin-top: 2px;

}

.Login-image {
  max-width:100%;
  pointer-events: none;
}

.Logo-image {
  max-width:100%;
  pointer-events: none;
  margin-top:30px;
}
.jsr{
  text-align: left;
}
.left_side_title{
  font-family: 'Arvo' !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  color: #fff;
}
.left_side_desc{
  font-family: 'Lora' !important;
  font-size: 23px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  max-width: 522px;
  color: #fff;
}
.card{
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius:10px;
}

.card-body{
  margin:0px !important;
  padding:0px !important;
}
.card-title{
  font-family: 'Arvo' !important;
  font-weight: 500 !important;
  margin-block:15px !important;
  font-size:35px !important;
  

}
.card-text{
  font-family: 'Lora'!important;
  font-size:20px !important;
}



.inputmail{
  font-family: 'Lora' !important;
  padding:10px;
  border-radius:8px;
  Width:100%;
  font-size: 15px;
  border: 2px solid #2799894d !important;
  color:#7A8A86;
  outline: none;
  box-sizing: border-box; /* Ensures padding is included in the element's width */
  transition: box-shadow 0s ease;
}

.inputmail:focus{
  box-shadow: 0 0 0 0px #27998999 !important;
  border:2px solid #27998999 !important;
}

.sendmagiclink{
  font-family: 'Arvo' !important;
  font-size: 17px !important;
  Width:100% !important;
  background-color:#279989 !important;
  color:white !important;
  border-radius:8px !important;
  border:none !important;
  padding:8px 0 !important;
  margin: 15px 0 30px 0 !important;
}

.sendmagiclink:hover{
  background-color:#1A7A6D !important;
}


.grid{
  align-items: center;
  justify-content: center;
}








  