/* Custom scrollbar styles */
.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #279989 transparent; /* For Firefox */
  }
  
  /* For WebKit-based browsers (Chrome, Safari) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #279989;
    border-radius: 8px;
    border: 2px solid transparent; /* Space around scrollbar thumb */
    background-clip: content-box;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #23897c;
  }
  
  .custom-scrollbar::-webkit-scrollbar-button {
    display: none; /* Hide scrollbar buttons if needed */
  }
  